import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"

import "../../../styles/pages/glasses.scss"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import GlassesHeading from "../../../components/GlassesHeading"
import Breadcrumbs from "../../../components/Breadcrumbs"
import GlassesFilter from "../../../components/GlassesFilter"
import GlassesList from "../../../components/GlassesList"
import ProductBanner from "../../../components/ProductBanner"
import { useDebounce, useGlasses } from "../../../services/hooks"
import CartContext from "../../../context/cartContext"

const Glasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern")
      )
    }
  )

  const right_side_text =
    "Looking to make a statement with your frames? Consider stylish and alluring cat eye glasses. These frames feature a distinctive elongated and flattened top arch, as well as prominent areas at the top edges. Hubble’s cat eye glasses come in a variety of styles for men and women.<br><br>No matter which frames you go with, you can find your dream pair of cat eye glasses with Hubble. Plus, every pair comes with impact-resistant lenses, durable barrel hinges, and padded temple tips for extra cush. Look good, see better—and feel great."
  const list_items = [
    "Premium, natural fiber acetate frames that are stronger, more durable, and last longer than plastic frames.",
    "Anti-reflective, anti-glare, anti-scratch, hydrophobic, and oleophobic coatings.",
    "Optional blue-light filtering.",
    "Hand-assembled and made to order cat eye glasses.",
  ]

  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: [],
    shape: ["cateye"],
    material: [],
    color: [],
    gender: [],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if (!debouncedFilter) return

    // navigateWithFilters(location.pathname, debouncedFilter)
  }, [debouncedFilter])

  useEffect(() => {
    setHeaderColor("orange")

    return () => {
      setHeaderColor("black")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'optical',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Optical-By-Frame-Cat-Eye`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])
  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/shop-by-frame-shape/cat-eye-frame-glasses",
      "@type": "webpage",
      "name": "Shop the Best Cat-Eye Frame Glasses and Sunglasses | Hubble",
      "description": "Try a unique look with our cat-eye frame eyeglasses and sunglasses. Each pair of Hubble Glasses is hand-assembled and meticulously designed to help you look and feel your best in any style!",
      "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
      }
    }
  ]

  return (
    <Layout className="glasses">
      <SEO
        title="Shop the Best Cat Eye Frame Glasses"
        description="Try a unique look with our cat eye frame eyeglasses. Each pair of Hubble Glasses is hand-assembled and meticulously designed to help you look and feel your best in any style!"
        schemaMarkup={schemaMarkup}
        />
      <GlassesHeading
        color="orange"
        title="Shop Cat Eye Frame Glasses"
        description="Looking for a perfect fit? Let us help you find the best cat eye
          glasses for men and women, starting at just $49.99."
      />

      <div className="glasses-container">
        <div className="grid-section">
          <Breadcrumbs
            color="orange"
            links={[{ to: "/", label: "Homepage" }, { label: "Eyeglasses" }]}
          />
          <GlassesFilter
            type="glasses"
            glassesLength={sortedGlasses.length}
            filter={filter}
            setFilter={setFilter}
          />
          <GlassesList
            type="glasses"
            glasses={sortedGlasses}
            color={filter.color}
            gender={filter.gender[0]}
          />
          <ProductBanner
            title={"Hubble has the best selection of cat eye glasses frames."}
            right_side_text={right_side_text}
            list_items={list_items}
            optional_image="Hubble-Glasses-PCP-Inset-Lifestyle-Image.jpg"
            optional_image_alt_text="Woman wearing Hubble eyeglasses while enjoing time at home"
            color="orange"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Glasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
